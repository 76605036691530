import React from "react";
import { PageLayout } from "../../components/PageLayout";
import { HomeCarousel } from "./HomeCarousel.js";
import { FeatureCategories } from "./FeatureCategories";
import SEO from "../../components/seo";
import Catalogue from "../../components/ui/Catalogue";
import FeedbacksCarousel from "./FeedbacksCarousel";

const HomePage = () => {
  return (
    <PageLayout>
      <SEO
        title="ABI MOULDINGS"
        description="Discover elegance and durability with ABI Mouldings' high-quality interior mouldings and MDF interior doors. Explore our range of stylish baseboards, casing, cornices, and headers, crafted to enhance your home's aesthetic appeal. Experience exceptional craftsmanship and customer service. Elevate your interior design with ABI Mouldings today."
        name="ABI Mouldings"
        type="article"
      />
      <HomeCarousel />
      <FeatureCategories />
      <Catalogue src={"https://heyzine.com/flip-book/162decfbeb.html"} />
      <section className="rev-container ">
        <div className="container mx-auto ">
          <FeedbacksCarousel />
        </div>
      </section>
    </PageLayout>
  );
};
export default HomePage;
